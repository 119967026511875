@import "https://fonts.googleapis.com/css?family=Quicksand";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "lib/assets/scss/colors.scss";
@import "lib/assets/scss/sizes.scss";


html,
body {
    margin: 0;
    font-family: 'Quicksand';
    font-size: 16px;


}

button {
    font-family: 'Quicksand';
}

.back-drop-background {
    background-color: rgba(0, 0, 0, 0.5);
}


.mat-mdc-option.mdc-list-item-disabled {
    opacity: 0;

}

.mat-mdc-option.mdc-list-item {
    min-height: 32px;
    border-bottom: 1px solid $fourth-color;

    &:hover {
        background: #eee;
    }

}

.cdk-overlay-pane {
    background: white;
    font-size: $small-font;


}

.mat-mdc-select-panel-above .mdc-menu-surface.mat-mdc-select-panel {
    position: absolute;
    background: white;
}

.mat-mdc-select-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    font-size: $small-font;



}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #efeff5;

}

.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
    font-style: 'QuickSand';
}

.NgxEditor__Content {
    height: 300px;
    overflow-y: scroll;
}

.mat-mdc-form-field {
    width: 60%;
}

@media(max-width:$tablet-view) {
    .cdk-overlay-pane {
        font-size: $mini-font;


    }

    .mat-mdc-select-trigger {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        font-size: $mini-font;


    }

}
